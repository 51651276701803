"use strict";

const {
    PAGE_LOAD,
    SEARCH_FORM_SUBMIT,
    STORE_SEARCH,
    STORE_CHANGE,
    SEARCH_SUGGEST_CLICK,
    CART_ADD,
    CART_ADD_ERROR,
    EMAIL_SIGNUP_OVERLAY_VIEW,
    EMAIL_SIGNUP,
    MINICART_OPEN,
    FAVORITES_ADD,
    FAVORITES_ADD_ERROR,
    // OFFER_CLICK,
    ACCOUNT_DRAWER,
    LOGIN_FAILURE,
    JOIN_FAILURE,
    LOGIN_COMPLETE,
    ACCOUNT_CREATE,
    ACCOUNT_LOGOUT,
    PASSWORD_RESET_START,
    PASSWORD_RESET_COMPLETE,
    PROMO_CODE_ADD,
    PROMO_CODE_REMOVE,
    PROMO_CODE_ERROR,
} = require("../models/Base");
class BaseAnalytics {

    /**
     * This constructor sets the @property { string } this.file to be able to track which child module may be throwing an error.
     *
     * @memberof BaseAnalytics
     */
    constructor(childFile, domData, pageModel) {
        const PAGE_DATA = document.getElementById("js-a-page-data");
        let pageName = PAGE_DATA && ("type" in PAGE_DATA.dataset) ? PAGE_DATA.dataset.type.toUpperCase() : childFile || "/BaseAnalytics.js";

        this.file = pageName;
        this.domData = domData || {};
        this.pageModel = pageModel || PAGE_LOAD;
    }

    /**
     * Contains all user interaction event listeners for static elements
     * The elements that are the target of these events exist at page load
     * and they are not re-inserted in the page to loose reference
     *
     * @memberof BaseAnalytics
     */

    baseEvents() {
        this.addCollectionNativeEventListeners("submit", document.getElementsByClassName("js-a-search-form"), this.setEventSearchFormSubmit);
        this.addNativeEventListeners("click", document.getElementById("js-a-suggestion-wrapper"), event => this.searchSuggestClickEvent(event));
        this.addCollectionNativeEventListeners("click", document.getElementsByClassName("js-a-toggle-login-tab"), event => this.prepareSingleEvent(ACCOUNT_DRAWER, event.target.dataset));
        this.addCollectionNativeEventListeners("click", document.getElementsByClassName("js-a-logout"), () => this.prepareSingleEvent(ACCOUNT_LOGOUT));
        this.addCollectionNativeEventListeners("submit", document.getElementsByClassName("js-submit-new-password"), () => this.prepareSingleEvent(PASSWORD_RESET_COMPLETE));
    }

    /**
     * Contains all listeners to events fired by page functionalities
     *
     * @memberof BaseAnalytics
     */
    baseInterceptedEvents() {
        this.addNativeEventListeners("storeSearch", window, event => this.storeSearchEvent(event));
        this.addNativeEventListeners("addToCartSuccess", window, event => this.addToCartSuccess(event));
        this.addNativeEventListeners("addToCartFail", window, event => this.addToCartFailure(event));
        this.addNativeEventListeners("minicartShow", window, this.minicartShow);
        this.addNativeEventListeners("addToFavoriteSuccess", window, event => this.addToFavoriteSuccess(event));
        this.addNativeEventListeners("addToFavoriteFail", window, event => this.addToFavoriteFailure(event));
        this.addNativeEventListeners("triggerEmailSignupOverlayView", window, () => this.triggerOverviewOpen());
        this.addNativeEventListeners("triggerEmailSignup", window, event => this.prepareSingleEvent(EMAIL_SIGNUP, event.detail));
        // this.addNativeEventListeners("offerClick", window, event => this.prepareSingleEvent(OFFER_CLICK, event.detail));
        this.addNativeEventListeners("loginDrawerOpen", window, event => this.prepareSingleEvent(ACCOUNT_DRAWER, event.detail));
        this.addNativeEventListeners("loginFailed", window, event => this.prepareSingleEvent(LOGIN_FAILURE, event.detail));
        this.addNativeEventListeners("joinFailed", window, event => this.prepareSingleEvent(JOIN_FAILURE, event.detail));
        this.addNativeEventListeners("accountCreateFailed", window, event => this.prepareSingleEvent(JOIN_FAILURE, event.detail));
        this.addNativeEventListeners("loginSuccess", window, event => this.prepareSingleEvent(LOGIN_COMPLETE, event.detail));
        this.addNativeEventListeners("joinSuccess", window, event => this.prepareSingleEvent(ACCOUNT_CREATE, event.detail));
        this.addNativeEventListeners("accountCreateSuccess", window, event => this.prepareSingleEvent(ACCOUNT_CREATE, event.detail));
        this.addNativeEventListeners("startPasswordReset", window, () => this.prepareSingleEvent(PASSWORD_RESET_START));
        this.addNativeEventListeners("completePasswordReset", window, () => this.prepareSingleEvent(PASSWORD_RESET_COMPLETE));
        this.addNativeEventListeners("promoAdd", window, event => this.prepareSingleEvent(PROMO_CODE_ADD, event.detail));
        this.addNativeEventListeners("promoRemove", window, event => this.prepareSingleEvent(PROMO_CODE_REMOVE, event.detail));
        this.addNativeEventListeners("promoError", window, event => this.prepareSingleEvent(PROMO_CODE_ERROR, event.detail));
        this.addNativeEventListeners("storeChanged", window, event => this.storeChangeEvent(event.detail.target));
    }

    /**
     * Contains all user interaction event listeners for dynamic elements
     * The elements that are the target of these events may not exist at page load
     * or are inserted in the page
     *
     * @memberof BaseAnalytics
     */
    baseDelayedEvents() {
        this.addEventWatcher("click", document.getElementsByClassName("js-location-modal"), ".js-a-store-change", target => this.storeChangeEvent(target));
        this.triggerOverviewOpen();
    }

    /**
     * Prepares DOM elements for analytic collection and Tag Manager read
     *
     * @memberof BaseAnalytics
     */
    prepareDOMElements() {
        this.setTagsNavigation();
        this.setTagsSubNavigation();
        this.setTagsFooter();
    }

    /**
     * Triggers the overview open event if the signup modal is opened.
     * Prepares a single event for email signup overlay view.
     * Resets the `openedSignupModal` flag to 0, which is used on the signup modal to check if it was opened or not when initializing analytics.
     * Treats the edge case analytics event listeners triggers after the event is dispatched in the main.js functionality
     *
     * @memberof BaseAnalytics
     * @method triggerOverviewOpen
     */

    triggerOverviewOpen() {
        if (window.openedSignupModal === 1) {
            this.prepareSingleEvent(EMAIL_SIGNUP_OVERLAY_VIEW);
            window.openedSignupModal = 0;
        }
    }

    /**
     * Automate link preparation on content heavy PD pages
     *
     * @memberof BaseAnalytics
     */
    preparePageDesignerData() {
        let pdTarget = document.getElementsByClassName("experience-region");

        if (pdTarget.length > 0) {
            let layouts = pdTarget[0].children;
            const PAGE_DATA = document.getElementById("js-a-page-data");
            const PAGE_NAME = PAGE_DATA ? PAGE_DATA.dataset.name.replace(/ /g, "_") : "";
            const PAGE_VERSION = PAGE_DATA ? PAGE_DATA.dataset.version : "";
            const SEQUENCE_START = "a";

            for (let i = 0; i < layouts.length; i++) {
                let layoutSequence = String.fromCharCode(SEQUENCE_START.charCodeAt(0) + i);
                const carouselClasses = ["experience-commerce_layouts-carousel", "experience-commerce_layouts-navigationCarousel"];
                let isCarousel = carouselClasses.some(className => layouts[i].classList.contains(className));
                let assetQuery = "[class*='experience-commerce_assets']";
                let componentTitleObj = layouts[i].getElementsByClassName("js-a-pd-title");
                let componentTitle = componentTitleObj.length > 0 ? componentTitleObj[0].dataset.pdTitle.replace(/ /g, "_") : "";

                if (isCarousel) {
                    assetQuery = ".title-container, .slick-slide:not(.slick-cloned) [class*='experience-commerce_assets']";
                }

                let assets = layouts[i].querySelectorAll(assetQuery);

                for (let j = 0; j < assets.length; j++) {
                    let componentSequence = `${j + 1}`;

                    let links = assets[j].getElementsByTagName("a");

                    for (let k = 0; k < links.length; k++) {
                        if (links[k].href != "") {
                            let linkURL = new URL(links[k]);
                            let linkOrigin = linkURL.origin;
                            let linkPathName = linkURL.pathname;
                            let urlParams = new URLSearchParams(linkURL.search);
                            let updatedURL = `${PAGE_NAME}:${PAGE_VERSION}:${layoutSequence}:${componentSequence}:${linkPathName}:${componentTitle}`;

                            urlParams.append("ab", updatedURL);

                            let replacedURL = `${linkOrigin}${linkPathName}?${decodeURIComponent(urlParams)}`;

                            links[k].setAttribute("href", replacedURL);
                        }
                    }
                }
            }
        }
    }

    /**c
     * Adds a vanilla js event handler to items found via selector
     *
     * @param {String} eventName
     * @param {HTMLElement} selector
     * @param {Function} handler
     *
     * @memberof BaseAnalytics
     */
    addNativeEventListeners(eventName, selector, handler) {
        if (selector) {
            selector.addEventListener(eventName, handler.bind(this));
        }
    }

    /**
     * Adds a vanilla js event handler to items found via selector
     *
     * @param {String} eventName
     * @param {HTMLCollection} selector
     * @param {Function} handler
     *
     * @memberof BaseAnalytics
     */
    addCollectionNativeEventListeners(eventName, selector, handler) {
        for (let element of selector) {
            element.addEventListener(eventName, handler.bind(this));
        }
    }

    /**
     * Adds a vanilla js event handler that delegates events only fires when the selector is identified
     *
     * @memberof BaseAnalytics
     * @param {String} eventName
     * @param {HTMLCollection} parent
     * @param {String} parent
     * @param {Function} handler
     */
    addEventWatcher(eventName, parent, selector, handler) {
        for (let element of parent) {
            element.addEventListener(eventName, event => {
                let targetFound = event.target.closest(selector);

                if (targetFound) {
                    handler(targetFound);
                }
            });
        }
    }

    /**
     * Adds an intersection observer to look for the given selector
     *
     * @memberof BaseAnalytics
     * @param {String} selector
     * @param {Function} handler
     */
    addIntersectionObserver(selector, handler) {
        let observer = new IntersectionObserver(handler.bind(this));
        observer.observe(document.querySelector(selector));
    }

    /**
     * Loops through an array of option objects, and returns the selected one
     *
     * @memberof BaseAnalytics
     * @param {Array} options
     * @returns {HTMLElement} DOM element of selected option
     */
    getSelectedOption(options) {
        let selected = null;
        for (const item of options) {
            if (item.selected) {
                selected = item;
            }
        }
        return selected;
    }

    /**
     * Prepare search form submit event
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    setEventSearchFormSubmit(e) {
        let domData = {
            keyword: e.currentTarget.getElementsByClassName("search-field")[0].value
        };

        this.prepareSingleEvent(SEARCH_FORM_SUBMIT, domData);
    }

    /**
     * Prepare suggestion click event
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    searchSuggestClickEvent(e) {
        if (e.target.classList.contains("js-a-suggestion-click")) {
            const SEARCHVALUE = document.getElementById("js-a-search-input").value;
            let domData = e.target.dataset;

            domData.autoSuggestTerm = SEARCHVALUE;

            this.prepareSingleEvent(SEARCH_SUGGEST_CLICK, domData);
        }
    }

    /**
     * Prepare store search event
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    storeSearchEvent(e) {
        const storeObj = document.getElementById("js-a-store-locator-search");
        const storeSearchObj = document.getElementById("store-postal-code");

        const storeSearchData = storeObj ? storeObj.dataset.searchResults : "";
        const storeSearchValue = storeSearchObj ? storeSearchObj.value : storeSearchObj;
        const GEO_LOCATE = "geo-locate";

        let domData = {
            searchPhrase: e.detail.isGeolocate ? GEO_LOCATE : storeSearchValue,
            searchResults: storeSearchData,
            searchType: e.detail.isGeolocate ? GEO_LOCATE : "manual"
        };

        this.prepareSingleEvent(STORE_SEARCH, domData);
    }

    /**
     * Prepare store change event
     * @param {HTMLElement} target - HTML element containing store data
     *
     * @memberof BaseAnalytics
     */
    storeChangeEvent(target) {
        const previousStoreObj = document.getElementsByClassName("js-a-store-current");
        const previousStoreName = previousStoreObj.length > 0 ? previousStoreObj[0].textContent.trim() : "";

        let domData = {
            previousName: previousStoreName,
            name: target.dataset.storeName
        };

        this.prepareSingleEvent(STORE_CHANGE, domData);
    }

    /**
     * Prepare add to cart success event for product tiles and PDP
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    addToCartSuccess(e) {
        const eventData = e.detail;

        for (let i = 0; i < eventData.products.length; i++) {
            eventData.products[i].categoryNames = JSON.parse(eventData.products[i].categoryNames).reverse();
        }

        let domData = {
            ...eventData,
        };

        this.prepareSingleEvent(CART_ADD, domData, false);
    }

    /**
     * Prepare add to cart failure event for product tiles and PDP
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    addToCartFailure(e) {
        const eventData = e.detail.data;
        let domData = {
            type: eventData
        };

        this.prepareSingleEvent(CART_ADD_ERROR, domData);
    }

    /**
     * Prepare add to favorite success event for product tiles and PDP
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    addToFavoriteSuccess(e) {
        const eventData = e.detail.data;
        let domData = {
            products: [
                {
                    collectionId: eventData.collectionId,
                    id: eventData.id,
                    kitId: eventData.kitId,
                    price: eventData.price,
                    quantity: eventData.quantity,
                    sku: eventData.sku
                }
            ],
            name: eventData.favoritesListName
        };

        this.prepareSingleEvent(FAVORITES_ADD, domData, false);
    }

    /**
     * Prepare add to favorite failure event for product tiles and PDP
     * @param {Object} e - Event object
     *
     * @memberof BaseAnalytics
     */
    addToFavoriteFailure(e) {
        const eventData = e.detail.data;
        let domData = {
            type: eventData
        };

        this.prepareSingleEvent(FAVORITES_ADD_ERROR, domData);
    }

    /**
     * Prepare add to cart success event for product tiles and PDP
     *
     * @memberof BaseAnalytics
     */
    minicartShow() {
        let minicartObj = document.getElementById("js-a-minicart-data");

        let productData = {
            ...JSON.parse(minicartObj.dataset.products),
        };

        this.prepareSingleEvent(MINICART_OPEN, productData, false);
    }

    /**
     * Adds necessary tags for header navigation to be picked by datalayer
     * Covers main navigation on large viewport and all small viewport navigation
     *
     * @memberof BaseAnalytics
     */
    setTagsNavigation() {
        const NAV_PARENTS = document.getElementsByClassName("js-a-nav-init");

        [...NAV_PARENTS].forEach(NAV_PARENT => {
            const MAIN_NAV_ITEMS = NAV_PARENT.getElementsByClassName("nav-item");
            const SECONDARY_NAV_ITEMS = NAV_PARENT.getElementsByClassName("dropdown-item");

            this.setNavNameValue([...MAIN_NAV_ITEMS], ".nav-link span");
            this.setNavNameValue([...SECONDARY_NAV_ITEMS], "span");
        });
    }

    /**
     * Adds necessary tags for header navigation to be picked by datalayer
     * Covers subnavigation in large viewport
     *
     * @memberof BaseAnalytics
     */
    setTagsSubNavigation() {
        const SUBNAV_PARENTS = document.getElementsByClassName("js-a-subnav-init");

        [...SUBNAV_PARENTS].forEach(SUBNAV_PARENT => {
            const MAIN_SUBNAV_ITEMS = SUBNAV_PARENT.getElementsByClassName("dropdown-item");
            const SECONDARY_SUBNAV_ITEMS = SUBNAV_PARENT.getElementsByClassName("subnav-item");

            this.setNavNameValue([...MAIN_SUBNAV_ITEMS], "span");
            this.setNavNameValue([...SECONDARY_SUBNAV_ITEMS], "a");
        });
    }

    /**
     * Adds necessary tags for footer navigation to be picked by datalayer
     *
     * @memberof BaseAnalytics
     */
    setTagsFooter() {
        const FOOTER_LINKS = document.getElementById("js-a-footer-init");

        if (FOOTER_LINKS) {
            const linkItems = FOOTER_LINKS.getElementsByClassName("menu-footer");

            for (let i = 0; i < linkItems.length; i++) {
                let navItem = linkItems[i].parentElement.getElementsByClassName("footer-column-title");

                if (navItem.length > 0) {
                    linkItems[i].dataset.navName = navItem[0].textContent.trim().replace(/&nbsp;/g, "");
                }
            }
        }
    }

    /**
     * Retrieves, parses and sets navigation item dataset values
     * @param {HTMLAllCollection} navCollection - Elements to process
     * @param {String} navDataEl - Query used to find elements thta hold data
     *
     * @memberof BaseAnalytics
     */
    setNavNameValue(navCollection, navDataEl) {
        let navNamePrefix = "";
        for (let i = 0; i < navCollection.length; i++) {
            let navItem = navCollection[i].querySelector(navDataEl);

            if (navItem.classList.contains("secondary-nav-item")) {
                navNamePrefix = "secondary:";
            }

            if (navItem) {
                navCollection[i].dataset.navName = navNamePrefix + navItem.textContent.trim().replace(/&nbsp;/g, "");
            }
        }
    }

    /**
     * Recursive function that checks all object values of a model or a dataset
     * Check if model expects an array and parses the response to avoid escaped characters
     * If the model key-value is an object, re-call the function
     * If the model key-value is an array:
     * - parse data if array of strings ["valA", "valB"]
     * - use an array to collect all values if an array of objects [{key:"value1"},{key:"value2"}] and does not eliminate duplicates
     * - will not use the model value if there's no dom data value
     * If the model key-value is a string, populate the respective data. If no data is found, use the model
     * There can be always 4 types of property values:
     * 1. Strings
     * 2. Arrays
     * 3. Objects
     * 4. Arrays of Objects
     *
     * @param {Object} eventModel - The object to iterate through the model
     * @param {Object} DOMData - The object to iterate through DOM Data
     * @param {Boolean} scrapModel - Used for when array of objects are met
     * @returns {Object} Returns completed event data
     *
     * @memberof BaseAnalytics
     */
    getEventData(eventModel, DOMData, scrapModel = true) {
        let objectKeys = Object.keys(eventModel);
        let objToPopulate = {};

        objectKeys.forEach(key => {
            const MODEL_KEY_TYPE = Object.prototype.toString.call(eventModel[key]);
            const DOM_KEY_TYPE = Object.prototype.toString.call(DOMData[key]);

            switch (MODEL_KEY_TYPE) {
                case "[object Object]":
                    objToPopulate[key] = this.getEventData(eventModel[key], DOMData, scrapModel);
                    break;
                case "[object Array]":
                    if (key in DOMData) {
                        let array = [];

                        if (DOM_KEY_TYPE != "[object String]") {
                            for (let item of DOMData[key]) {
                                array.push(item);
                            }
                            objToPopulate[key] = array;
                        } else {
                            objToPopulate[key] = DOMData[key].length > 0 ? JSON.parse(DOMData[key]) : [];
                        }
                    } else {
                        if (scrapModel) {
                            objToPopulate[key] = [];
                        } else {
                            objToPopulate[key] = eventModel[key];
                        }
                    }
                    break;
                default:
                    if (key in DOMData) {
                        if (key == "orderId") {
                            objToPopulate["id"] = DOMData[key];
                        } else {
                            objToPopulate[key] = DOMData[key];
                        }
                    } else {
                        objToPopulate[key] = eventModel[key];
                    }
                    break;
            }
        });

        return objToPopulate;
    }

    getEventName(eventModel) {
        return eventModel.eventName;
    }

    /**
     * Helper function that checks event model against DOM data
     *
     * @param {Array} DOMClassList - Array with all IDs to be queried for information
     * @param {Object} model - Event model used to map data
     * @returns {Object} Object with parsed
     *
     * @memberof BaseAnalytics
     */
    getDOMData(eventModel, DOMClassList) {
        let fullDOMDataset = {};
        let eventData = {};

        DOMClassList.forEach(item => {
            const ITEM_OBJ = document.getElementById(item);

            if (ITEM_OBJ) {
                const ITEM_DATASET = ITEM_OBJ.dataset;

                fullDOMDataset = Object.assign(fullDOMDataset, ITEM_DATASET);
            }
        });

        eventData = this.getEventData(eventModel.eventData, fullDOMDataset);

        return eventData;
    }

    /**
     * On load of all pages, analytics view data is sent to the tagging manager
     * @param {Object} helperObject - Optional parameter that can be used to enhance page data
     *
     * @memberof BaseAnalytics
     */
    getPageData(helperObject = {}) {
        let pageData = this.getDOMData(this.pageModel, this.domData);

        if (Object.keys(helperObject).length != 0) {
            pageData = this.getEventData(pageData, helperObject, false);
        }

        window.pageData = pageData;

        this.signalEvent(this.getEventName(this.pageModel));
    }

    /**
     * Helper function that checks event model against DOM data
     *
     * @param {Array} domData - Array with all IDs to be queried for information
     * @param {Object} model - Event model used to map data
     * @param {Boolean} scrapModel - Used for when array of objects are met
     * @returns {Object} Object with parsed
     *
     * @memberof BaseAnalytics
     */
    prepareSingleEvent(eventModel, domData = {}, scrapModel = true) {
        const EVENT_DATA = this.getEventData(eventModel.eventData, domData, scrapModel);

        this.signalEvent(this.getEventName(eventModel), EVENT_DATA);
    }

    /**
     * Helper function that triggers event for the tag manager to collect
     *
     * @param {String} eventName - Event name to be dispatched
     * @param {String} eventData - Event data to be dispatched
     * @returns {Object} Object with parsed
     *
     * @memberof BaseAnalytics
     */
    signalEvent(eventName, eventData = {}) {
        const eventDataLogged = eventName == "pageLoaded" ? window.pageData : eventData;
        const style = "border-width: 1px; border-style: solid; padding: 5px;";

        if (Object.keys(eventData).length > 0 && eventName != "pageLoaded") {
            eventData = {
                detail: eventData,
            };
        }

        window.dispatchEvent(new CustomEvent(eventName, eventData));

        console.log("====================================================================");
        console.log(`%c${this.file} - Event: ${eventName}`, style);
        console.group("Interactible Log");
        console.log(eventDataLogged);
        console.groupEnd();
        console.groupCollapsed("Persistent Log");
        console.log(JSON.stringify(eventDataLogged));
        console.groupEnd();
        console.log("====================================================================");

    }

}

module.exports = BaseAnalytics;
